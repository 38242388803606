


/* Colors variables - couleur primaire, secondaire, tertiaire... */
/* Couleurs relatives aux bordures */
/* Couleurs relatives aux backgrounds */
/* variables : tailles */
/* variables */
/* variables : breakpoint - template : Material UI */
/* Style valable pour toute l'app */




/* Écran petit (téléphone en mode portrait) */


/* Écran moyen (tablette) */

/* Écran moyen à grand (tablette ou ordinateur portable) */
@media screen and (min-width: 1440px) and (max-width: 2560px) {

  body {
    margin: 0;
    padding: 0;
    background: #000;
    color: #ffffff;
    box-sizing: border-box;
    font-size: 1vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    width:100%;
  }
  
  .top-right-icon {
    right: 5%;
  
    font-size: 4vw;
  
    position: fixed;
  
    display: flex;
    color: white;
    font-weight: lighter;
    z-index: 10000;
    width: 4vw;
    height: 4vw;
    transform: rotate(45deg);
    border: solid 2px rgb(255, 255, 255);
    border-bottom: none;
    border-left: none;
  
  }
  
  
  .top-left-icon {
    color: white;
    /* transform-origin: top left; */
  
  
    display: flex;
    left: 5%;
    position: fixed;
    font-size: 4vw;
    font-weight: lighter;
    z-index: 10000;
    width: 4vw;
    height: 4vw;
    transform: rotate(45deg);
    border: solid 2px rgb(255, 255, 255);
    border-top: none;
    border-right: none;
    margin: 0;
  }
  .container {
    width: 100%;
    /* height: 121%;  */
    /* //background: white !important; */
    position: relative;
    display: flex;
    justify-content: space-evenly;
  }
  
  .top-right {
    display: flex;
    width: 10%;
    position: fixed;
    top: 0px;
    background: #000;
    margin-top: 0px;
    overflow: auto;
    right: 0;
    z-index: 2;
    height: 100%;
  }
  
  a {
    text-decoration: none;
    margin: 0;
   

  }
  
  .top-left {
    display: flex;
    width: 10%;
    background: #000;
    position: fixed;
    top: 0px;
    margin-top: 0px;
    overflow: auto;
    left: 0;
    z-index: 2;
    height: 100%;
  }
  
  .top-left-nico {
    color: grey;
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
    margin-top: 25px;
  
  
  }
  
  .top-left-travaux {
    color: rgba(255, 88, 0, 1);
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
    cursor:pointer;
    display: flex;
    align-items:center
    
   
  
  }
  
  .top-left-travaux-white {
    color: rgb(255, 255, 255);
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
  
  }
  
  .top-left-ensemble {
  
    display: flex;
    transform: rotate(-180deg);
    transform-origin: center center;
    writing-mode: vertical-lr;
    margin: 0px auto auto auto;
  
  }
  
  .top-left-contact {
    /* color: white; */
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
    margin: auto;
    color: rgba(255, 88, 0, 1);
  
  }
  
  .top-left-contact-white {
    /* color: white; */
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
    margin: auto;
    color: rgb(255, 255, 255);
  
  }
  
  .top-right-icon {
    right: 5%;
  
    
    position: fixed;
    margin-top: 0vw;
    display: flex;
    color: white;
    font-weight: lighter;
    z-index: 10000;
    width: 4vw;
    height: 4vw;
    transform: rotate(45deg);
    border: solid 2px rgb(255, 255, 255);
    border-bottom: none;
    border-left: none;
  
  }
  
  
  .top-left-icon   {
    color: white;
    /* transform-origin: top left; */
    margin-top: 0vw;
  
    display: flex;
    left: 5%;
    position: fixed;
   
    font-weight: lighter;
    z-index: 10000;
    width: 4vw;
    height: 4vw;
    transform: rotate(45deg);
    border: solid 2px rgb(255, 255, 255);
    border-top: none;
    border-right: none;
    
  }
 
  
  
  .iconmui {
    font-size: 1.4vw;
    color: white;
    transform: rotate(-180deg);
    transform-origin: center center;
    writing-mode: vertical-lr;
    margin: 10px 10px auto auto;
  }
  
  .central {
    width: 100%;
     /* height: 101vh;  */
  background: rgb(0, 0, 0); 
    margin: 40px auto 20px auto;
  
  }
  
  .central-travaux {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    margin: 40px auto 0px auto;
    pointer-events: none;
    width: 80%;
    /* height: 1000px;*/
  
  }
  
  .central-travaux:hover .thumb:not(:hover) {
    opacity: 0.3;
  
  }
  
  .thumb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.3s;
    /* margin-top: 20px; */
    pointer-events: auto;
  
  }
  
  .thumb:hover p {
  
    color: #ff5800;
  }
  
  .pixThumb {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  
  }
  
  .firstPixP {
    
    margin: 15px auto 2.5px auto;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
  }
  
  .secondPixP {
    font-size: 0.64vw;
    margin: 2.5px auto 15px auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
  }
  .doublepara {
    height: 61vh;
    display: flex;
    margin: auto auto;
    flex-direction: column;
    justify-content: space-around;
  }
  .central-nicocarmine {
  
   
    width: 80%;
  
    background: rgb(0, 0, 0);
    margin: 55px auto 55px auto;
  }
  
  .central-pix {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0px auto;
    height:100%;
  
    background: rgb(0, 0, 0);
  
  }
  
  .central-text {
  
    margin: auto auto;
    font-size: 2.8vw;
    font-style: normal;
    line-height: 8vh;
    height: 100%;
    font-family: "Inter", sans-serif;
    text-align: justify;
  
  }
  
  .pix {
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
  
  }
  
  .pixHead {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  
  }
  
  .pixFirst {
    width: 100%;
    height: 100%;
    ;
  
    margin: 40px auto 40px auto;
  }
  
  .text {
    display: flex;
    flex-direction: row;
    font-family: "Inter";
    font-style: normal;
    width: 80%;
    z-index: 1000000;
    align-items: center;
    background: rgb(0, 0, 0);
    margin: 0 auto 0 auto;
    padding: 20px;
   
  }
  
  .text-left {
    display: flex;
    flex-direction: column;
    width: 40%;
    font-family: "Inter";
    font-style: normal;
    background: rgb(0, 0, 0);
    margin: 0;
    /* align-items:center;*/
  
  } 
  .text-right {
    width: 60%;
    font-family: "Inter";
    font-style: normal;
    background: rgb(0, 0, 0);
    margin: 0;
  }
  
  .text-content {
    background: rgb(0, 0, 0);
    margin: 0 auto 1vh 0;
    font-family: "Inter";
    font-style: normal;
  }
  
  .text-content-titre {
    font-size: 2.5vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    margin-bottom: 0;
    margin-top:0;
  }
  
  .text-content-soustitre {
    font-size: 2vw;
    font-family: "Inter";
    font-style: normal;
    margin-bottom: 1vh;
    margin-top:0;
    font-weight: 300;
  }
  
  .text-content-soustitre2 {
    font-size: 2vw;
    font-family: "Inter";
    font-style: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 300;
  }
  
  .text-content-credit {
    font-size: 0.8vw;
    font-weight: 100;
    font-family: "Inter";
    font-style: normal;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .text-content-des {
   
    font-size: 1.3vw;
    line-height: 1.5 !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
  }
  
  
  .Divider {
    border: solid 1px white;
  }
  
  .root {
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  }
  
  .content {
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #f58608;
  }
  
  .textes {
    font-weight: bold;
    text-align: center;
  }
  
  .column-nicocarmine {
    display: flex;
    justify-content: space-between;
    margin-bottom:7vh;
    align-items:baseline;
  }
  
  .title-nicocarmine {
    color: #ff5800;
    font-size: 3.2vw;
    margin: 0px 20px 0px 0px;
   
    font-weight:bold;
      font-family: "Futura";
      font-style:normal;
    /* //margin: 10.5vh 0 0 0;
    //line-height: 2px; */
  }
  
  .divider-icon {
    display: flex;
flex-direction: row;
justify-content: space-between;

margin-bottom: 2vh;
height: 100%;
align-items: center;
margin-left: 10%;
margin-right: 10%;
  }
  
  .divider1-white {
    margin: 0 !important;
    width: 90%;
    -webkit-flex-shrink: 0 !important;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider1-white2 {
    margin: 0 !important;
    width: 100%;
    -webkit-flex-shrink: 0 !important;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider2-white {
    margin: 0 20px auto 0px !important;
    width: 100%;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-bottom-width: 0px;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider2-white22 {
   
    width: 100%;
    border-bottom: 2px solid white;
  
  
  }
  
  .divider2-white222 {
    display: flex;
    flex-direction: row;
    /* margin: 0 20px 20px 20px; */
    justify-content: space-between;
    width: 100%;
    
    border-bottom: 2px solid white;
  
  
  }
  
  /* .doublepara{
    height:60vh;
    
  } */
  .SlideRoutes {
    overflow: auto !important;
    height: max-content;
  }
  
  .icon-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    transform: rotate(-180deg);
    transform-origin: center center;
    /* writing-mode: vertical-lr; */
    margin: 0 10px;
  }
  
  .icon-group2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  
    /* writing-mode: vertical-lr; */
    
  }
  .groupicon  {
    display:flex;
    font-size:0.8vw;
    color: white;
    margin: 0;
  }
  .pgroupicon  {
    align-self: end;
    margin: 0;
  }
  .iconmuiContact {
    font-size: 2.5vw;
    color: white;
  
    /* margin: 1vh 0 1vh 0.5vw; */
  }
  
  .iconButton {
    width: 70px;
    height: 70px;
    transform: rotate(-45deg);
    border: solid 2px rgba(255, 88, 0, 1);
    border-top: none;
    border-right: none
  }
  
  .lien-contact {
    color: #ff5800;
    font-family: "Inter";
    font-size:1vw;
    font-weight: bold;
    cursor:pointer;
    margin: 0 1vw 0 1vw!important;
    width: auto;
  }
  
  .containerContact {
    display: flex;
    flex-direction: column;
    /* width:90vw; */
    margin: auto;
    height: 100%;
  }
  
  .containerRow {
    display: flex;
    flex-direction: row;
    color: rgba(255, 88, 0, 1);
    margin: 40px 10% 0;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    height: 30%;
    align-items: center;
  }
  
  .containerContactNico {
   
    font-size: 2vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal;
   
   
  }
  
  .containerContactNico2 {
   
    font-size: 1vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactNico3 {
    margin: auto 10px;
    font-size: 1vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactexpertise {
    margin: 20px auto 30px auto;
    font-size: 3.5vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactclient {
    margin: 20px auto 30px 0;
    font-size: 3.5vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactrien {
    margin: 20px auto 30px 0;
    font-size: 3.5vw;
    color: black;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactlist {
    margin: 10px 0;
    font-size: 1.5vw;
    font-weight: 200;
    font-family: "inter";
    font-style: normal;
    line-height: 3vh;
  
  }
  
  .containerRow3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 10%;
    
  }
  
  .listcontact {
    display: flex;
    flex-direction: column;
  height: 75vh;
  }
  .MuiContainer-root {
    min-height: 64px;
  max-width: none !important;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3vh;
  margin-top: 2vh;
  }
  .MuiToolbar-root {
    min-height: 64px;
    max-width:none !important;
    width: 80%;
    margin-left:10%;
    margin-right: 10%;
    padding-left:0px !important;
    padding-right:0px !important;
    display: flex;
    justify-content: space-between;
  }
  .MuiPaper-root {
    background-color: #0c0c0c !important;
    color: #ffffffde;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12);
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: none !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 1200;
   
    position: fixed;
    top: 0;
    outline: 0;
    right: 0;
    width: 100%;
  }
  .button {
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    background-color: transparent !important;
    outline: 0 !important;
    border: 0 !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    vertical-align: middle !important;
 
  
    text-decoration: none !important;
  
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500 !important;
    font-size: 1.1vw !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase !important;
    min-width: 64px !important;
    padding: 6px 8px !important;
  
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: #ffffff !important;
    margin: auto !important;
    border-radius: 50% !important;
    height: 100px !important;
    width: 100px !important;
  }
  
  .groupbouton {
    margin: auto;
    display: flex;
  
  }
  .appbar2 {
    min-height: 64px;
  max-width: none !important;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: flex-end;
  
  }
  .menuContainer {
    width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  height:100%;
  }
  .menuDivcentral {
      display:flex;
      flex-direction: column;
      align-items: center;
      height:85%;
      justify-content: center;
      margin-top:-4vh;
      margin-bottom:-7vh
    }
    .spantext1  {
      display:flex;
      margin: auto;
      text-transform: none;
      
    }
    .menuDivtext1   {
      display:flex;
     margin: 0 auto 0 auto;
      border-top: solid 0.7vh white;
      border-bottom: solid 0.7vh white;
      font-size: 12vw;
      font-weight: bold;
      font-family: "Futura";
      font-style: normal;
      align-items: center;
      width:100%;
      padding-bottom:1.2vh
    }
    .menuDivtext2 {
      display:flex;
     
      
      border-bottom: solid 0.7vh white;
      font-size:12vw;
      font-weight: bold;
      font-family: "Futura";
      font-style: normal;
      align-items: center;
      width:100%;
      padding-bottom:1.2vh
    }
  
  .menuicon {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 11.2vw!important;
    height: 11.2vw!important;
    display: inline-block;
    fill: rgba(255, 88, 0, 1) !important;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 11.2vw;
  }
  .iconmuiContact2 {
    font-size: 10vw;
      color: white;
    
      
    }
    .icon-group3 {
      display: flex;
      justify-content: space-around;
      width: 50%;
        margin: auto auto 6vh auto ;
      }
      .icon-group4 {
        display: flex;
        justify-content: space-around;
        width: 50%;
        margin: auto;
        }
        .lien-contact2 {
          display: flex;
          justify-content: space-around;
          width: 50%;
            margin: auto;
            color:#ff5800;
            font-size: 3.8vw;
      font-weight: 600;
      font-family: "Futura";
      font-style: normal;
      cursor:pointer;
          }
  /* .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 800ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-in;
  }
  
  .slide-enter {
    transform: translateX(-100%);
  }
  
  .slide-enter-active {
    transform: translateX(0%);
    transition: transform 800ms ease-in;
  }
  
  .slide-exit {
    transform: translateX(0%);
  }
  
  .slide-exit-active {
    transform: translateX(100%);
    transition: transform 800ms ease-in;
  } */
  
}

@media screen and (min-width: 769px) and (max-width: 1439px) {
  /* Styles pour les tablettes et écrans de taille similaire */
  body {
    margin: 0;
    padding: 0;
    background: #000;
    color: #ffffff;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    width:100%;
   
   
  }
  
  .container {
    width: 100%;
    /* height: 121%;  */
    /* //background: white !important; */
    position: relative;
    display: flex;
    justify-content: space-evenly;
  }
  
  .top-right {
    display: flex;
    width: 10%;
    position: fixed;
    top: 0px;
    background: #000;
   
    overflow: auto;
    right: 0;
    z-index: 10000;
    height: 100%;
  }
  
  a {
    text-decoration: none;
    margin: 0;
  }
  
  .top-left {
    display: flex;
    width: 10%;
    background: #000;
    position: fixed;
    top: 0px;
    
    overflow: auto;
    left: 0;
    z-index: 10000;
    height: 100%;
  }
  
  .top-left-nico {
    color: grey;
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
    margin-top: 25px;
  
  
  }
  
  .top-left-travaux {
    color: rgba(255, 88, 0, 1);
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
    cursor:pointer;
    display: flex;
    align-items: center;
   
  
  }
  
  .top-left-travaux-white {
    color: rgb(255, 255, 255);
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
  
  }
  
  .top-left-ensemble {
  
    display: flex;
    transform: rotate(-180deg);
    transform-origin: center center;
    writing-mode: vertical-lr;
    margin: 0px auto auto auto;
  
  }
  
  .top-left-contact {
    /* color: white; */
   /* color: white; */
   font-size: 2.2vw;
   font-family: "Futura";
   font-style: normal;
   font-weight: 600;
  
   margin: auto;
   color: rgba(255, 88, 0, 1);
 
 }
 
 .top-left-contact-white {
   /* color: white; */
   font-size: 2.2vw;
   font-family: "Futura";
   font-style: normal;
   font-weight: 600;
  
   margin: auto;
   color: rgb(255, 255, 255);
 
 }
  
 .top-right-icon {
  right: 5%;

  font-size: 4vw;

  position: fixed;

  display: flex;
  color: white;
  font-weight: lighter;
  z-index: 10000;
  width: 4vw;
  height: 4vw;
  transform: rotate(45deg);
  border: solid 2px rgb(255, 255, 255);
  border-bottom: none;
  border-left: none;

}


.top-left-icon {
  color: white;
  /* transform-origin: top left; */


  display: flex;
  left: 5%;
  position: fixed;
  font-size: 4vw;
  font-weight: lighter;
  z-index: 10000;
  width: 4vw;
  height: 4vw;
  transform: rotate(45deg);
  border: solid 2px rgb(255, 255, 255);
  border-top: none;
  border-right: none;
  margin: 0;
}
  
  
  .iconmui {
    font-size:1.4vw;
    color: white;
    transform: rotate(-180deg);
    transform-origin: center center;
    writing-mode: vertical-lr;
    margin: 10px 10px auto auto;
  }
  
  .central {
    width: 100%;
    /* height: 101vh; 
    //background: rgb(0, 0, 0); */
    margin: 40px auto 20px auto;
  
  }
  
  .central-travaux {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    margin: 40px auto 0px auto;
    pointer-events: none;
    width: 80%;
    /* height: 1000px;*/
  
  }
  
  .central-travaux:hover .thumb:not(:hover) {
    opacity: 0.3;
  
  }
  
  .thumb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.3s;
    /* margin-top: 20px; */
    pointer-events: auto;
  
  }
  
  .thumb:hover p {
  
    color: #ff5800;
  }
  
  .pixThumb {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  
  }
  
  .firstPixP {
    
    margin: 15px auto 2.5px auto;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 1.4vw;
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
  }
  
  .secondPixP {
    font-size: 0.8vw;
    margin: 2.5px auto 15px auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
  }
  .doublepara {
    height: 68vh;
    display: flex;
    margin: auto auto;
    flex-direction: column;
    justify-content: space-around;
  }
  .central-nicocarmine {
  

    width: 80%;
  
    background: rgb(0, 0, 0);
    margin: 50px auto 20px auto;
  }
  
  .central-pix {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0px auto;
  
    background: rgb(0, 0, 0);
  
  }
  
  .central-text {
  
    margin: auto auto;
    font-size: 3vw;
    font-style: normal;
    line-height: 8vh;
    height: 100%;
    font-family: "Inter", sans-serif;
    text-align: justify;
  
  }
  
  .pix {
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
  
  }
  
  .pixHead {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  
  }
  
  .pixFirst {
    width: 100%;
    height: 100%;
    ;
  
    margin: 40px auto 40px auto;
  }
  
  .text {
    display: flex;
    flex-direction: row;
    font-family: "Inter";
    font-style: normal;
    width: 80%;
    z-index: 1000000;
    align-items: center;
    background: rgb(0, 0, 0);
    margin: 0 auto 0 auto;
    padding: 20px;
   
  }
  
  .text-left {
    display: flex;
    flex-direction: column;
    width: 40%;
    font-family: "Inter";
    font-style: normal;
    background: rgb(0, 0, 0);
    margin: 0;
    /* align-items:center;*/
  
  } 
  .text-right {
    width: 60%;
    font-family: "Inter";
    font-style: normal;
    background: rgb(0, 0, 0);
    margin: 0;
  }
  
  .text-content {
    background: rgb(0, 0, 0);
    margin: 0 auto 1vh 0;
    font-family: "Inter";
    font-style: normal;
  }
  
  .text-content-titre {
    font-size: 2.5vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    margin-bottom: 0;
    margin-top:0;
  }
  
  .text-content-soustitre {
    font-size: 2vw;
    font-family: "Inter";
    font-style: normal;
    margin-bottom: 1vh;
    margin-top:0;
    font-weight: 300;
  }
  
  .text-content-soustitre2 {
    font-size: 2vw;
    font-family: "Inter";
    font-style: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 300;
  }
  
  .text-content-credit {
    font-size: 0.8vw;
    font-weight: 100;
    font-family: "Inter";
    font-style: normal;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .text-content-des {
   
    font-size: 1.3vw;
    line-height: 1.5 !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
  }
  
  .Divider {
    border: solid 1px white;
  }
  
  .root {
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  }
  
  .content {
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #f58608;
  }
  
  .textes {
    font-weight: bold;
    text-align: center;
  }
  
  .column-nicocarmine {
    display: flex;
    justify-content: space-between;
    align-items:baseline;
    margin-bottom:6vh;
  }
  
  .title-nicocarmine {
    color: #ff5800;
    font-size: 3.2vw;
    margin: 0px 20px 0px 0px;
   
    font-weight:bold;
      font-family: "Futura";
      font-style:normal;
    /* //margin: 10.5vh 0 0 0;
    //line-height: 2px; */
  }
  
  .divider-icon {
    display: flex;
flex-direction: row;
justify-content: space-between;

margin-bottom: 2vh;
height: 100%;
align-items: center;
margin-left: 10%;
margin-right: 10%;
  }
  
  .divider1-white {
    margin: 0 !important;
    width: 90%;
    -webkit-flex-shrink: 0 !important;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider1-white2 {
    margin: 0 !important;
    width: 100%;
    -webkit-flex-shrink: 0 !important;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider2-white {
    margin: 0 20px auto 0px !important;
    width: 100%;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-bottom-width: 0px;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider2-white22 {
    margin: 0 20px 0px 20px;
    width: 100%;
    border-bottom: 2px solid white;
  
  
  }
  
  .divider2-white222 {
    display: flex;
    flex-direction: row;
    /* margin: 0 20px 20px 20px; */
    justify-content: space-between;
    width: 100%;
    height: 25px;
    border-bottom: 2px solid white;
  
  
  }
  
  /* .doublepara{
    height:60vh;
    
  } */
  .SlideRoutes {
    overflow: auto !important;
    height: max-content;
  }
  
  .icon-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    transform: rotate(-180deg);
    transform-origin: center center;
    /* writing-mode: vertical-lr; */
    margin: 0 10px;
  }
  
  .icon-group2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  
    /* writing-mode: vertical-lr; */
    margin: -15px 0px 10px 5px;
  }
  
  .iconmuiContact {
    font-size: 1vw;
    color: white;
  
    margin: 10px 0px 10px 5px;
  }
  
  .iconButton {
    width: 70px;
    height: 70px;
    transform: rotate(-45deg);
    border: solid 2px rgba(255, 88, 0, 1);
    border-top: none;
    border-right: none
  }
  
  .lien-contact {
    color: #ff5800;
    font-family: "Inter";
    font-size:1vw;
    font-weight: bold;
    margin: auto 20px 5px 0 !important;
    width: auto;
    cursor:pointer;
  }
  
  .containerContact {
    display: flex;
    flex-direction: column;
    /* width:90vw; */
    margin: auto;
    height: 100vh;
  }
  .icon-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    transform: rotate(-180deg);
    transform-origin: center center;
    /* writing-mode: vertical-lr; */
    margin: 0 10px;
  }
  
  .icon-group2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  
    /* writing-mode: vertical-lr; */
    
  }
  .groupicon  {
    display:flex;
    font-size:0.8vw;
    color: white;
    margin: 0;
  }
  .pgroupicon  {
    align-self: end;
    margin: 0;
  }
  .iconmuiContact {
    font-size: 2.5vw;
    color: white;
  
    /* margin: 1vh 0 1vh 0.5vw; */
  }
  
  .iconButton {
    width: 70px;
    height: 70px;
    transform: rotate(-45deg);
    border: solid 2px rgba(255, 88, 0, 1);
    border-top: none;
    border-right: none
  }
  
  .lien-contact {
    color: #ff5800;
    font-family: "Inter";
    font-size:1vw;
    font-weight: bold;
    cursor:pointer;
    margin: 0 1vw 0 1vw!important;
    width: auto;
  }
  
  .containerContact {
    display: flex;
    flex-direction: column;
    /* width:90vw; */
    margin: auto;
    height: 100%;
  }
  
  .containerRow {
    display: flex;
    flex-direction: row;
    color: rgba(255, 88, 0, 1);
    margin: 40px 10% 0;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    height: 30%;
    align-items: center;
  }
  
  .containerContactNico {
   
    font-size: 2vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal;
   
   
  }
  
  .containerContactNico2 {
   
    font-size: 1vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactNico3 {
    margin: auto 10px;
    font-size: 1vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactexpertise {
    margin: 20px auto 30px auto;
    font-size: 3.5vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactclient {
    margin: 20px auto 30px 0;
    font-size: 3.5vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactrien {
    margin: 20px auto 30px 0;
    font-size: 3.5vw;
    color: black;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactlist {
    margin: 10px 0;
    font-size: 1.5vw;
    font-weight: 200;
    font-family: "inter";
    font-style: normal;
    line-height: 3vh;
  
  }
  
  .containerRow3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 10%;
    
  }
  
  .listcontact {
    display: flex;
    flex-direction: column;
  height: 75vh;
  }
  
  .button {
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    background-color: transparent !important;
    outline: 0 !important;
    border: 0 !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    vertical-align: middle !important;
  
  
    text-decoration: none !important;
  
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase !important;
    min-width: 64px !important;
    padding: 6px 8px !important;
  
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: #ffffff !important;
    margin: auto !important;
    border-radius: 50% !important;
    height: 100px !important;
    width: 100px !important;
  }
  
  .groupbouton {
    margin: auto;
    display: flex;
  
  }
  
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 800ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-in;
  }
  
  .slide-enter {
    transform: translateX(-100%);
  }
  
  .slide-enter-active {
    transform: translateX(0%);
    transition: transform 800ms ease-in;
  }
  
  .slide-exit {
    transform: translateX(0%);
  }
  
  .slide-exit-active {
    transform: translateX(100%);
    transition: transform 800ms ease-in;
  }
  .appbar2 {
    min-height: 64px;
  max-width: none !important;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: flex-end;
  
  }
  .menuContainer {
    width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  height:100%;
  }
  .menuDivcentral {
      display:flex;
      flex-direction: column;
      align-items: center;
      height:85%;
      justify-content: center;
      margin-top:-4vh;
      margin-bottom:-7vh
    }
    .spantext1  {
      display:flex;
      margin: auto;
      text-transform: none;
      
    }
    .menuDivtext1   {
      display:flex;
     margin: 0 auto 0 auto;
      border-top: solid 0.7vh white;
      border-bottom: solid 0.7vh white;
      font-size: 12vw;
      font-weight: bold;
      font-family: "Futura";
      font-style: normal;
      align-items: center;
      width:100%;
      padding-bottom:1.2vh
    }
    .menuDivtext2 {
      display:flex;
     
      
      border-bottom: solid 0.7vh white;
      font-size:12vw;
      font-weight: bold;
      font-family: "Futura";
      font-style: normal;
      align-items: center;
      width:100%;
      padding-bottom:1.2vh
    }
  
  .menuicon {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 11.2vw!important;
    height: 11.2vw!important;
    display: inline-block;
    fill: rgba(255, 88, 0, 1) !important;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 11.2vw;
  }
  .iconmuiContact2 {
    font-size: 10vw;
      color: white;
    
      
    }
    .icon-group3 {
      display: flex;
      justify-content: space-around;
      width: 50%;
        margin: auto auto 6vh auto ;
      }
      .icon-group4 {
        display: flex;
        justify-content: space-around;
        width: 50%;
        margin: auto;
        }
        .lien-contact2 {
          display: flex;
          justify-content: space-around;
          width: 50%;
            margin: auto;
            color:#ff5800;
            font-size: 3.8vw;
      font-weight: 600;
      font-family: "Futura";
      font-style: normal;
      cursor:pointer;
          }
}

@media screen and (max-width: 768px) {
  /* Styles pour les écrans étroits comme les téléphones */
  body {
    /* margin: 0;
    padding: 0 !important; */
    background: #000;
    color: #ffffff;
    box-sizing: border-box;
    font-size: 1vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    overflow: auto;
    margin:0;
    width: 100vw;
    height: 100vh;
   
  }
  .MuiPaper-root {
    background-color: #000000 !important;
    color: #ffffff;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12);
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 12;
  
    position: fixed;
    top: 0;
    outline: 0;
    right: 0;
    width: 100%;;
    
  }
  .spanlinktext  {
    color:white !important;
    cursor:pointer;
  }
  .MuiContainer-root {
    min-height: 64px;
  max-width: none !important;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3vh;
  margin-top: 2vh;
  }
  .MuiToolbar-root {
    min-height: 64px;
    max-width:none !important;
    width: 80%;
    margin-left:10%;
    margin-right: 10%;
    padding-left:0px !important;
    padding-right:0px !important;
    display: flex;
    justify-content: space-between;
  }
  .appbar3 {
    min-height: 64px;
  max-width: none !important;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0vh;

  
  }
  .container {
    width: 100%;
    /* height: 121%;  */
    /* //background: white !important; */
    position: relative;
    display: flex;
    justify-content: space-evenly;
  }
  
  .top-right {
    display: none;
    width: 10%;
    position: fixed;
    top: 0px;
    background: #000;
    margin-top: 0px;
    overflow: auto;
    right: 0;
    z-index: 2;
    height: 100%;
  }
  
  a {
    text-decoration: none;
    margin: 0;
  }
  
  .top-left {
    display: none;
    width: 10%;
    background: #000;
    position: fixed;
    top: 0px;
    margin-top: 0px;
    overflow: auto;
    left: 0;
    z-index:2;
    height: 100%;
  }
  
  .top-left-nico {
    color: rgba(255, 88, 0, 1);
font-size: 7.2vw;
font-family: "Futura";
font-style: normal;
font-weight: 600;
  
  
  }
  
  .top-left-travaux {
    color: rgba(255, 88, 0, 1);
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   display:none
  
  }
  
  .top-left-travaux-white {
    color: rgb(255, 255, 255);
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
  
  }
  
  .top-left-ensemble {
  
    display:flex;
    transform: rotate(-180deg);
    transform-origin: center center;
    writing-mode: vertical-lr;
    margin: 0px auto auto auto;
  
  }
  
  .top-left-contact {
    /* color: white; */
    /* color: white; */
    font-size: 2.2vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
    margin: auto;
    color: rgba(255, 88, 0, 1);
  
  }
  
  .top-left-contact-white {
    /* color: white; */
    font-size: 12vw;
    font-family: "Futura";
    font-style: normal;
    font-weight: 600;
   
    margin: auto;
    color: rgb(255, 255, 255);
  
  }
  
  .top-right-icon {
    right: 5%;
  
    font-size: 4vw;
  
    position: fixed;
  
    display: flex;
    color: white;
    font-weight: lighter;
    z-index: 2;
    width: 4vw;
    height: 4vw;
    transform: rotate(45deg);
    border: solid 2px rgb(255, 255, 255);
    border-bottom: none;
    border-left: none;
    margin: 10% 0 0 0;
  }
  
  
  .top-left-icon {
    color: white;
    /* transform-origin: top left; */
 
    display: flex;
    left: 5%;
    position: fixed;
    font-size: 4vw;
    font-weight: lighter;
    z-index: 2;
    width: 4vw;
    height: 4vw;
    transform: rotate(45deg);
    border: solid 2px rgb(255, 255, 255);
    border-top: none;
    border-right: none;
    margin: 10% 0 0 0;
  }
  
  
  .iconmui {
    font-size: 1.3vw;
    color: white;
    transform: rotate(-180deg);
    transform-origin: center center;
    writing-mode: vertical-lr;
    margin: 10px 10px auto auto;
  }
  
  .central {
    width: 100%;
    /* height: 101vh; 
    //background: rgb(0, 0, 0); */
    margin: 1vh auto 1vh auto;
  
  }
  
  .central-travaux {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    margin: 2vh auto 1vh auto;
    pointer-events: none;
    width: 80%;
    /* height: 1000px;*/
  
  }
  
  .central-travaux:hover .thumb:not(:hover) {
    opacity: 0.3;
  
  }
  
  .thumb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.3s;
    /* margin-top: 20px; */
    pointer-events: auto;
  
  }
  
  .thumb:hover p {
  
    color: #ff5800;
  }
  
  .pixThumb {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  
  }
  
  .firstPixP {
    font-size: 2.5vw;
    margin: 15px auto 2.5px auto;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
   
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
  }
  
  .secondPixP {
    font-size: 1.64vw;
    margin: 2.5px auto 15px auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
  }
  .doublepara {
    
    display: flex;
    margin: auto auto;
    flex-direction: column;
    justify-content: space-around;
  }
  .central-nicocarmine {
  
   
    width: 80%;
  
    background: rgb(0, 0, 0);
    margin: 0px auto 55px auto;
  }
  
  .central-pix {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0px auto;
  
    background: rgb(0, 0, 0);
  
  }
  
  .central-text {
  
   
    font-size: 6vw;
    font-style: normal;
    line-height: 4.4vh;
    height: 100%;
    font-family: "Inter", sans-serif;
  
  }
  
  .pix {
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
  
  }
  
  .pixHead {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  
  }
  
  .pixFirst {
    width: 100%;
    height: 100%;
    ;
  
    margin: 40px auto 40px auto;
  }
  
  .text {
    display: flex;
    flex-direction: column;
    font-family: "Inter";
    font-style: normal;
    width: 80%;
    z-index: 1000000;
    align-items: center;
    background: rgb(0, 0, 0);
    margin: 20px auto 0px auto;
  }
  
  .text-left {
    display: flex;
    flex-direction: column;
    
    font-family: "Inter";
    font-style: normal;
    background: rgb(0, 0, 0);
    margin: 0 0px 0px 0px;
    align-self: first baseline;
  }
  
  .text-right {
    
    font-family: "Inter";
    font-style: normal;
    background: rgb(0, 0, 0);
    margin: 0 0 1vh 0;
  }
  
  .text-content {
    background: rgb(0, 0, 0);
    margin: 0 auto 20px 0;
    font-family: "Inter";
    font-style: normal;
  }
  
  .text-content-titre {
    font-size: 3.5vw;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 10px;
  }
  
  .text-content-soustitre {
    font-size: 3.5vw;
    font-family: "Inter";
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 0;
    font-weight: 200;
  }
  
  .text-content-soustitre2 {
    font-size: 3.5vw;
    font-family: "Inter";
    font-style: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 200;
  }
  
  .text-content-credit {
    font-size: 1.8vw;
    font-weight: 100;
    font-family: "Inter";
    font-style: normal;
    
  }
  
  .text-content-des {
   
    font-size: 2.3vw;
    line-height: 1.75 !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
  }
  
  
  .Divider {
    border: solid 1px white;
  }
  
  .root {
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  }
  
  .content {
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #f58608;
  }
  
  .textes {
    font-weight: bold;
    text-align: center;
  }
  
  .column-nicocarmine {
    display: none;
    justify-content: space-between;
    align-items:baseline;
    margin-bottom:4vh;
  }
  
  .title-nicocarmine {
    color: #ff5800;
    font-size: 2.2vw;
    margin: 0px 0px 0px 0px;
   
    font-weight:bold;
      font-family: "Futura";
      font-style:normal;
    /* //margin: 10.5vh 0 0 0;
    //line-height: 2px; */
  }
  
  .divider-icon {
    display: flex;
    /* visibility: hidden; */
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 2vh auto 2vh auto;
    align-items: center;
    
  }
  
  .divider1-white {
    margin: 0 !important;
    width: 90%;
    -webkit-flex-shrink: 0 !important;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider1-white2 {
    margin: 0 !important;
    width: 100%;
    -webkit-flex-shrink: 0 !important;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider2-white {
    margin: 0 20px auto 0px !important;
    width: 100%;
    flex-shrink: 0 !important;
    border-width: 0 !important;
    border-bottom-width: 0px;
    border-style: solid !important;
    border-color: rgb(255, 255, 255) !important;
    border-bottom-width: thin !important;
  }
  
  .divider2-white22 {
    margin: 0 0px 0px 0px;
    width: 100%;
    border-bottom: 2px solid rgb(0, 0, 0);
  
  
  }
  
  .divider2-white222 {
    display: flex;
    flex-direction: column;
    /* margin: 0 20px 20px 20px; */
    justify-content: space-between;
    width: 100%;
    
    border-bottom: 2px solid white;
  
  
  }
  .groupicon {
    display:none;
    font-size:2.7vw;
     font-weight:lighter;
    font-family: "Inter";
  font-style:normal; 
  margin: auto  ;
  width:fit-content
  }
  /* .doublepara{
    height:60vh;
    
  } */
  .SlideRoutes {
    overflow: auto !important;
    height: max-content;
  }
  
  .icon-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    transform: rotate(-180deg);
    transform-origin: center center;
    /* writing-mode: vertical-lr; */
    margin: 0 10px;
  }
  
  .icon-group2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  
    /* writing-mode: vertical-lr; */
    margin: auto 0 4vh;
  }
  
  .iconmuiContact {
  font-size: 8vw;
    color: white;
  
    margin: 10px 0px 10px 5px;
  }
  
  .iconButton {
    width: 11vw;
height: 11vw;
transform: rotate(-45deg);
border: solid 2px rgba(255, 88, 0, 1);
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: rgb(255, 88, 0);
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: rgb(255, 88, 0);
border-top: none;
border-right: none;
  }
  
  .lien-contact {
    color: #ff5800;
    font-family: "Inter";
    font-size:2vw;
    cursor:pointer;
    font-weight: bold;
    margin: auto 2vw auto 2vw  !important;
    width: auto;
  }
  
  .containerContact {
    display: flex;
    flex-direction: column;
   
    margin: auto;
    height: 100vh;
  }
  
  .containerRow {
    display: flex;
    flex-direction: column;
    color: rgb(255, 255, 255);
    margin: 0 0 4vh 0;
    padding: 0 0 4vh 10%;
   
    border-bottom: 0.5vh solid white;
    height:10%;
    z-index:0;
    justify-content: space-between;
  }
  
  .containerContactNico {
    margin: 0.2vh 0;
    font-size:4vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactNico2 {
    margin: 0.2vh 0;
    font-size: 3.1vw;
    font-weight: 100;
    
    font-style: normal
  
  }
  
  .containerContactNico3 {
    margin: 0.2vh 0;
    font-size: 3.1vw;
    font-weight: 100;
    
    font-style: normal
  
  }
  
  .containerContactexpertise {
    margin: 0 0 1vh 0;
    font-size:9vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal;
    color: #ff5800;
  
  }
  
  .containerContactclient {
    margin: 4vh 0 1vh 0;
    font-size:9vw;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal;
    color: #ff5800;
  
  }
  
  .containerContactrien {
    display:none;
    margin-top:  -9.2vh;
    margin-left:  -8vh;
    font-size:4.5vw;
    color: black;
    font-weight: bold;
    font-family: "Futura";
    font-style: normal
  
  }
  
  .containerContactlist {
    margin: 1.5vh 0;
    font-size:4.5vw;
    font-weight: lighter;
    font-family: "inter";
    font-style: normal;
    line-height: 1vh;
  
  }
  
  .containerRow3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10%;
  
  }
  
  .listcontact {
    display: flex;
    flex-direction: column;
  margin-bottom:0
  }
  
  .button {
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    background-color: transparent !important;
    outline: 0 !important;
    border: 0 !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    vertical-align: middle !important;
  
  
    text-decoration: none !important;
  
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500 !important;
    font-size: 0.8vw !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase !important;
    min-width: 64px !important;
    padding: 6px 8px !important;
  
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: #ffffff !important;
    margin: auto !important;
    border-radius: 50% !important;
    height: 100px !important;
    width: 100px !important;
  }
  
  .groupbouton {
    margin: -4vh auto;
    display: flex;
  
  }
  
  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 800ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-in;
  }
  
  .slide-enter {
    transform: translateX(-100%);
  }
  
  .slide-enter-active {
    transform: translateX(0%);
    transition: transform 800ms ease-in;
  }
  
  .slide-exit {
    transform: translateX(0%);
  }
  
  .slide-exit-active {
    transform: translateX(100%);
    transition: transform 800ms ease-in;
  }
  .appbar2 {
    min-height: 64px;
  max-width: none !important;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: flex-end;
  
  }
  .menuContainer {
    width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  height:100%;
  }
  .menuDivcentral {
      display:flex;
      flex-direction: column;
      align-items: center;
      height:85%;
      justify-content: center;
      margin-top:-4vh;
      margin-bottom:-7vh
    }
    .spantext1  {
      display:flex;
      margin: auto;
      text-transform: none;
      
    }
    .menuDivtext1   {
      display:flex;
     margin: 0 auto 0 auto;
      border-top: solid 0.7vh white;
      border-bottom: solid 0.7vh white;
      font-size: 12vw;
      font-weight: bold;
      font-family: "Futura";
      font-style: normal;
      align-items: center;
      width:100%;
      padding-bottom:1.2vh
    }
    .menuDivtext2 {
      display:flex;
     
      
      border-bottom: solid 0.7vh white;
      font-size:12vw;
      font-weight: bold;
      font-family: "Futura";
      font-style: normal;
      align-items: center;
      width:100%;
      padding-bottom:1.2vh
    }
  
  .menuicon {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 11.2vw!important;
    height: 11.2vw!important;
    display: inline-block;
    fill: rgba(255, 88, 0, 1) !important;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 11.2vw;
  }
  .iconmuiContact2 {
    font-size: 10vw;
      color: white;
    
      
    }
    .icon-group3 {
      display: flex;
      justify-content: space-around;
      width: 50%;
        margin: auto auto 6vh auto ;
      }
      .icon-group4 {
        display: flex;
        justify-content: space-around;
        width: 50%;
        margin: auto;
        }
        .lien-contact2 {
          display: flex;
          justify-content: space-around;
          width: 50%;
          cursor:pointer;
            margin: auto;
            color:#ff5800;
            font-size: 3.8vw;
      font-weight: 600;
      font-family: "Futura";
      font-style: normal
          }
}
    .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 800ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-in;
  }
  
  .slide-enter {
    transform: translateX(-100%);
  }
  
  .slide-enter-active {
    transform: translateX(0%);
    transition: transform 800ms ease-in;
  }
  
  .slide-exit {
    transform: translateX(0%);
  }
  
  .slide-exit-active {
    transform: translateX(100%);
    transition: transform 800ms ease-in;
  }
  
  